import dayjs from "dayjs";
import React, { useContext, useState } from "react";

import Alert from "../../components/Alert/Alarm.jsx";
import LookupsContext from "../../context/LookupsContext";
import "./ClassForm.scss";

const ClassForm = ({
  entryData,
  city,
  component,
  courses,
  alertMessage,
  error,
  _onChange,
  _onSubmit,
  date,
  courseCalendar_Id,
  className,
  startTime,
  endTime,
  agendaURL,
  syllabusURL,
  scheduleType,
  courseModule,
}) => {
  const [holiday, setHoliday] = useState(scheduleType === "Holiday");
  const lookups = useContext(LookupsContext);

  return (
    <div className="new-class-container">
      <div className="page-title">
        <p>{city}</p>
        <i className="fas fa-chevron-right"></i>
        <p>{component === "newclass" ? "New Class" : "Edit Class"}</p>
      </div>
      <form className="new-class-form" noValidate onSubmit={_onSubmit}>
        {alertMessage && alertMessage !== "" ? (
          <Alert type={alertMessage.type} children={alertMessage.message} />
        ) : null}
        <div className="form-group">
          <label>Intake: </label>
          <select
            name="courseCalendar_Id"
            className="form-control"
            defaultValue={courseCalendar_Id}
            ref={(e) =>
              (entryData.current[0] = {
                element: e,
                required: true,
              })
            }
          >
            {courses &&
              courses.map((course, index) => {
                return (
                  <option key={index} value={course._id}>
                    {course.intakeName}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="date">Date: </label>
          <input
            name="date"
            type="date"
            defaultValue={dayjs(new Date(date)).format("YYYY-MM-DD")}
            className={
              error && error.date ? "form-control error" : "form-control"
            }
            onChange={(e) => _onChange(e)}
            ref={(e) =>
              (entryData.current[1] = {
                element: e,
                required: true,
              })
            }
          />
        </div>
        <div className="err-msg">
          {error && error.date && <p>* Date is required!</p>}
        </div>
        <div className="form-group">
          <label htmlFor="role">Schedule:</label>
          <select
            name="scheduleType"
            className="form-control"
            placeholder="Schedule"
            defaultValue={scheduleType}
            onChange={(e) => {
              setHoliday(e.target.value === "Holiday");
              _onChange(e);
            }}
            ref={(e) =>
              (entryData.current[2] = {
                element: e,
                required: true,
              })
            }
          >
            <option key="" value="">
              Please select . . .
            </option>
            {lookups.classTypes.map((ct) => (
              <option key={ct} value={ct}>
                {ct}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="ClassName">
            {holiday ? "Reason:" : "Class Name:"}
          </label>
          <input
            id="ClassName"
            name="className"
            defaultValue={className}
            className={
              error && error.className ? "form-control error" : "form-control"
            }
            type="text"
            placeholder={holiday ? "Reason . . ." : "e.g. Sprint 1"}
            onChange={(e) => _onChange(e)}
            ref={(e) =>
              (entryData.current[3] = {
                element: e,
                required: true,
              })
            }
          />
        </div>
        <div className="err-msg">
          {error && error.className && (
            <p> * {holiday ? "Reason" : "Class Name"} is required!</p>
          )}
        </div>
        {!holiday && (
          <>
            <div className="form-group">
              <label htmlFor="courseModule">Module:</label>
              <select
                name="courseModule"
                id="courseModule"
                defaultValue={courseModule}
                className={
                  error && error.courseModule
                    ? "form-control error"
                    : "form-control"
                }
                placeholder="Course Module"
                onChange={(e) => {
                  _onChange(e);
                }}
                ref={(e) =>
                  (entryData.current[4] = {
                    element: e,
                    required: true,
                  })
                }
              >
                <option key="" value="" data-testid="course-module">
                  Choose from the list...
                </option>
                {courseModule &&
                  !lookups.courseModules.includes(courseModule) && (
                    <option value={courseModule} data-testid="course-module">
                      {courseModule}
                    </option>
                  )}
                {lookups.courseModules.map((module, index) => (
                  <option
                    key={index}
                    value={module}
                    data-testid="course-module"
                  >
                    {module}
                  </option>
                ))}
              </select>
            </div>
            <div className="err-msg">
              {error && error.courseModule && (
                <p>* Course Module is required!</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="startTime">Start Time:</label>
              <input
                name="startTime"
                className={
                  error && error.startTime
                    ? "form-control error"
                    : "form-control"
                }
                type="time"
                defaultValue={startTime}
                onChange={(e) => _onChange(e)}
                ref={(e) =>
                  (entryData.current[5] = {
                    element: e,
                    required: true,
                  })
                }
              />
            </div>
            <div className="err-msg">
              {error && error.startTime && <p>* Start Time is required!</p>}
            </div>
            <div className="form-group">
              <label htmlFor="endTime">End Time:</label>
              <input
                name="endTime"
                defaultValue={endTime}
                className={
                  error && error.endTime ? "form-control error" : "form-control"
                }
                type="time"
                onChange={(e) => _onChange(e)}
                ref={(e) =>
                  (entryData.current[6] = {
                    element: e,
                    required: true,
                  })
                }
              />
            </div>
            <div className="err-msg">
              {error && error.endTime && <p>* End Time is required!</p>}
            </div>
            <div className="form-group font-size">
              <label htmlFor="agendaURL">Agenda URL:</label>
              <input
                name="agendaURL"
                type="url"
                defaultValue={agendaURL}
                className={
                  error && error.agendaURL
                    ? "form-control error"
                    : "form-control"
                }
                placeholder="Agenda URL"
                onChange={(e) => _onChange(e)}
                ref={(e) =>
                  (entryData.current[7] = {
                    element: e,
                    required: false,
                  })
                }
              />
            </div>
            <div className="err-msg">
              {error && error.agendaURL && <p>* {error.agendaURL}</p>}
            </div>
            <div className="form-group font-size">
              <label htmlFor="syllabusURL">Syllabus URL:</label>
              <input
                name="syllabusURL"
                type="url"
                defaultValue={syllabusURL}
                className={
                  error && error.syllabusURL
                    ? "form-control error"
                    : "form-control"
                }
                placeholder="Syllabus URL"
                onChange={(e) => _onChange(e)}
                ref={(e) =>
                  (entryData.current[8] = {
                    element: e,
                    required: true,
                  })
                }
              />
            </div>
            <div className="err-msg">
              {error && error.syllabusURL && <p>* {error.syllabusURL}</p>}
            </div>
          </>
        )}
        <div className="form-group">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
};

export default ClassForm;
