import axios from "axios";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useCallback, useEffect, useState } from "react";

import ClassCard from "../../components/ClassCard/ClassCard.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/Header.jsx";
import Loading2 from "../../components/Loading/Loading2.jsx";

import "./UpcomingClass.scss";

dayjs.extend(isSameOrAfter);

const UpcomingClass = ({ city, component }) => {
  const [upcomingCourseClasses, setUpcomingCourseClasses] = useState(null);

  

  const sortAndAddWeekNumber = (classes) => {
    let counter = 0;
    if (classes[0] && !classes[0].weekNumber) {
      classes = classes
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1))
        .map((c) => {
          c.weekNumber = c.scheduleType === "Holiday" ? "-" : ++counter;
          return c;
        });
    }
    return classes;
  };

  const getUpcomingClass = useCallback(async () => {
    try {
      const {
        data: { data: courses },
      } = await axios.get(`/courses/${city}`);

      const updatedCourses = courses.map((course) => {
        const sortedClasses = sortAndAddWeekNumber(course.classes);

        const upcomingClass = sortedClasses.find(
          (class_) =>
            class_.scheduleType !== "Holiday" &&
            dayjs(class_.date).isSameOrAfter(dayjs(), "day")
        );

        return {
          ...course,
          classes: sortedClasses,
          upcomingClass,
        };
      });

      const filteredCourses = updatedCourses.filter(
        (course) => course.upcomingClass
      );

      setUpcomingCourseClasses(filteredCourses);
    } catch (err) {
      console.error("Error fetching courses:", err);
    }
  }, [city]);

  useEffect(() => {
    getUpcomingClass();
  }, [getUpcomingClass]);

  return (
    <div>
      <Header city={city} component={component} />

      <div className="upcoming-class-container">
        {upcomingCourseClasses && upcomingCourseClasses.length > 0 ? (
          upcomingCourseClasses.map((upcomingCourse, index) => (
            <div key={index}>
              <div className="page-title">
                <p>{city}</p> <i className="fas fa-chevron-right"></i>
                <p>{upcomingCourse.intakeName}</p>
                <i className="fas fa-chevron-right"></i>
                <p>Upcoming Class</p>
              </div>

              <ClassCard
                city={city}
                component={component}
                Class={upcomingCourse.upcomingClass}
                WeekNumber={upcomingCourse.upcomingClass?.weekNumber}
              />
            </div>
          ))
        ) : (
          <div className="page-title">
            <p>{city}</p> <i className="fas fa-chevron-right"></i>
            <p>Upcoming Class</p>
            <Loading2 loaded={upcomingCourseClasses !== null} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UpcomingClass;
